<template>
  <div class="topic_warp"
       ref="topicWrapRef">

    <div class="wraper">
      <div class="topic_list"
           ref="imageWrapper">

        <div class="image_wrap">

        <div class="dopaper_card">
          <div class="image_wrap">
            <p class="title">答题情况：</p>
            <div class="card_warp">
              <div :class="getClass(item)"
                    v-for="(item,index) in paperList"
                    :key="index">
                <span v-if="checkTopic1.indexOf(item.topic) != -1||checkTopic.indexOf(item.topic) != -1">
                  {{getAvlue(item,index+1)}}
                </span>
                <span v-else>
                  <p>纸上作答</p>
                  <p>上传图片</p>
                </span>
              </div>
            </div>

            <!-- <el-button class="fixed_bottom_btn" @click="">统一上传</el-button>
            <el-button class="fixed_bottom_btn" @click="">按题上传</el-button> -->
            <div style="margin: 24px;">
              <el-radio-group v-model="uploadType" size="medium" @change="chooseUploadType">
                <el-radio-button label="all">统一上传</el-radio-button>
                <el-radio-button label="pre">按题上传</el-radio-button>
              </el-radio-group>
            </div>

            <div v-if='uploadType == "all"'>
              <p class="title">请添加图片:<span>（最多可添加6张图片）</span>
              </p>
              <div class="img_list">
                <div class="img_wrap"
                    v-for="(item,index) in images"
                    :key="index">
                  <img class="del"
                      @click="del(index)"
                      src="@/assets/icons/删除.png"
                      alt="">
                  <el-image class="answer"
                            :src="$addHeader(item)"
                            :preview-src-list="$addForHeader(item)"
                            alt=""></el-image>

                </div>
                <div class="add_img"
                    v-if="images.length  != 6"
                    @click="uploadImage">
                  <div>
                    <img src="@/assets/add_img.png"
                        alt="">
                  </div>
                  点击上传
                </div>
                <div v-if="1"
                    @click="btnTakePhotoClicked">
                  <video ref="video"
                        autoplay
                        width="400"
                        height="300"></video>
                </div>

                <canvas ref="canvas"
                        width="400"
                        style="display:none"
                        height="300"></canvas>

                <a href=""
                  style="display:none"
                  download="拍摄图片.jpeg"
                  id="save_herf">
                  <img src=""
                      id="save_img"
                      alt="">
                </a>

              </div>
            </div>

            <div v-else-if='uploadType == "pre"'>
              <p class="title">请添加图片:<span>（每题最多可添加3张图片）</span></p>

              <div class="img_list">
              <div v-for="(item,index) in paperList"
                    class="img_card"
                    v-show="item.topic!='1'&&item.topic!='2'&&item.topic!='3'&&item.topic!='4'&&item.topic!='9'"
                    :key="index">
                <!-- {{images}} -->
                <div style="font-size: 26px; width:50rem">{{index+1}} </div>
                <div class="img_wrap"
                      v-for="(imgChild,indexChild,k) in images"
                      v-if="item.answer_id == indexChild"
                      :key="indexChild">
                  <div v-if=" imgChild && imgChild.length>1 "
                        v-for="img,imgindex in imgChild"
                        :key="img">

                    <el-image class="answer"
                              :src="$addAllHeader(img)"
                              :preview-src-list="images[item.answer_id]"
                              alt=""></el-image>
                    <img class="del"
                          @click="del(item.answer_id,imgindex)"
                          src="@/assets/icons/删除.png"
                          alt="">
                  </div>
                  <div v-else>

                    <el-image class="answer"
                              :src="$addAllHeader(img)"
                              :preview-src-list="images[item.answer_id]"
                              alt=""></el-image>
                    <img class="
                          del"
                          @click="del(item.answer_id,imgindex)"
                          src="@/assets/icons/删除.png"
                          alt="">
                  </div>

                </div>
                <div class="add_img"
                      @click="uploadImage(item.answer_id)">
                  <div>
                    <img src="@/assets/add_img.png"
                          alt="">
                  </div>
                  点击上传
                </div>
              </div>
              </div>
            </div>
          </div>

          <!-- <div class="btn_warp">
            <el-button class="fixed_bottom_btn"
                        @click="showDialog">
              提 交
            </el-button>
          </div> -->
        </div>

        </div>
        <div class="btn_warp">
          <div class="submit"
               @click="showDialog">
            提交
          </div>
        </div>
      </div>
    </div>

    <CommenToUser ref="CommenToUserRef" />
    <Hint ref="hintRef" />
    <SubmitHint ref="SubmitHintRef" />

  </div>
</template>

<script>
import Dopaper from '@/views/components/topic/index.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { doPaper, saveAnswer, submitUserPaper } from '@/api/paper_template.js'
import RightScroll from '@/views/components/right_card/index.vue'
import CommenToUser from '@/views/paper_components/correct/components/comment.vue'
import Hint from '../doPaper/components/hint.vue'
import SubmitHint from '../dopaper_card/components/hint.vue'
import { chapterTrain } from '@/api/chapter.js'
import Code from '@/views/components/code/index.vue'
import ExportPaper from '@/views/components/exportPaper/index.vue'//导出试卷按钮
import { continueDoPaper, phonePaper } from '@/api/my_paper.js'
import { uploadFile, uploadImageAuto } from '@/api/upload.js'


export default {
  data () {
    return {
      images: [],
      paperInfo: {},
      flatList: {},
      topicNumber: 0,
      isSubmit: true,
      paperList: [],
      checkTopic: [1, 2, 3, 4, 9],
      checkTopic1: ['1', '2', '3', '4', '9'],
      uploadType: 'all',
    }
  },
  components: {
    Dopaper, TopCard, RightScroll, CommenToUser, Hint, SubmitHint, Code, ExportPaper
  },
  async mounted () {
    console.log('screenHeight: ' + this.screenHeight);
    // correct_type == 2 学生自评
    let userInfo = {
      token: this.$route.query.token
    }
    window.localStorage.setItem("userInfo", JSON.stringify(userInfo))
    this.getPaperInfo()
    this._initVueApp()
  },
  // 离开前做处理
  beforeDestroy () {
    if (this.images.length) {
      window.sessionStorage.setItem('image', JSON.stringify(this.images))
    }
    if (this.isSubmit) {
      this.savePaperAnswer()
    }
  },
  methods: {
    async getPaperInfo () {
      let params = {
        user_paper_id: this.$route.query.id,
        not_judged:0
      }
      const { data } = await continueDoPaper(params)

      // this.images = data.list.other_answer ? JSON.parse(data.list.other_answer) : []
      // var num = 6 - this.images.length
      // if (num > 0) {
      //   for (let index = 0; index < num; index++) {
      //     this.images.push('')
      //   }
      // }
      data.list.questions = data.list.questions.map(item => {
        item.question_big_score = 0;

        if (item.question_children && item.question_children.length) {

          let score = 0
          item.question_children = item.question_children.map(element => {
            score += Number(element.question_score);
            element.answer = []
            if (element.user_answer) {
              element.answer = element.user_answer.split(',')
            }
            return element
          });
          item.question_big_score = score;
        } else {
          item.answer = []
          if (item.user_answer) {
            item.answer = item.user_answer.split(',')
          }
        }
        return item;
      })

      this.paperInfo = data.list

      this.setFlatAndTopicNumber(this.paperInfo)

      // if (this.paperInfo.correct_type == 2) {
      let checkarr = [1, 2, 9, 4, 3]
      this.checkList = Object.values(this.flatList).filter(item => {
        return checkarr.includes(item.topic - 0)
      })
      var obj = {}
      this.checkList.forEach(item => {
        obj[item.answer_id] = item
      })
      this.checkList = Object.values(obj)

      this.activeList = Object.values(this.flatList).filter(item => {
        return !checkarr.includes(item.topic - 0) && Number(item.question_score)
      })
      var activeObj = {}
      this.activeList.forEach(item => {
        if (!item.score) {
          item.score = 0
        }

        if (!item.comment) {
          item.comment = ''
        }
        item.score = Number(item.score)
        activeObj[item.answer_id] = item
      })
      this.activeList = Object.values(activeObj)
      // }
    },
    setFlatAndTopicNumber (info) {
      // 扁平化 引用this的question 也就是说flatlist 变化 paperInfo.questions也要跟着变
      var list = this.flatQuestion(info.questions)
      var obj = {}
      var topicNumber = 0;
      list.forEach(item => {
        obj[item.question_id] = item
        if (Number(item.question_score)) {
          topicNumber += 1;
        }
      });
      this.flatList = obj
      this.paperList = this.getPaperList()
      console.log(this.paperList);

      // 获取共有多少题目，然后传递给右侧组件
      this.topicNumber = topicNumber
    },
    flatQuestion (list) {
      var allList = [];
      list.forEach(element => {
        allList.push(element)
        if (element.question_children && element.question_children) {
          allList = allList.concat(...element.question_children);
        }
      });
      return allList;
    },

    async _initVueApp () {
      try {
        // 获取设备列表
        const devices = await navigator.mediaDevices.enumerateDevices();
        let hasCamera = false;
        console.log("devices", devices);
        // 遍历设备列表，判断是否存在摄像头设备
        for (const device of devices) {
          console.log("device", device.kind);
          if (device.kind === 'videoinput') {

            hasCamera = true;
            break;
          }
        }
        console.log("hasCamera", hasCamera);
        if (hasCamera) {
          this.$refs.video.srcObject = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
          this._context2d = this.$refs.canvas.getContext("2d");
          this.canvas = this.$refs.canvas;
        } else {
          // this.$message.error("电脑设备没有摄像头");
        }
      } catch (error) {
        console.log('error', error)

        // this.$message.error("摄像头调用失败");
      }
    },
    btnTakePhotoClicked () {

      this._context2d.drawImage(this.$refs.video, 0, 0, 400, 300)
      var img = document.createElement("img"); // 创建img元素
      img.src = this.canvas.toDataURL("image/png"); // 截取视频第一帧
      var svaeHref = document.getElementById("save_herf");
      console.log(img.src)

      var sd = document.getElementById("save_img");
      svaeHref.href = img.src;
      sd.src = img.src
      svaeHref.click()

      this.uploadImage()


    },
    // 离开页面前保存答案
    async savePaperAnswer () {
      if (!this.paperInfo.user_paper_id) return
      let data = {
        user_paper_id: this.paperInfo.user_paper_id,
        user_answer: {},
        other_answer: JSON.stringify(this.images)
      }
      var flatList = Object.values(this.flatList)
      flatList.forEach(item => {
        if (Number(item.question_score) && item.answer.length) {
          data.user_answer[item.question_id] = item.answer.join(',')
        }
      })
      await saveAnswer(data)
    },
    getPaperList() {
      var list = {};
      var group_list = [];
      Object.values(this.flatList).forEach(item => {
        list[item.answer_id] = item
      })

      Object.values(list).forEach(item => {
        if (Number(item.question_score)) {
          group_list.push(item)
        }
      })

      return group_list
    },
    toCard () {
      let group_list = this.getPaperList()

      // 获取当前距离顶部距离，返回来的时候定位使用
      var oapp = document.getElementsByClassName('topic_warp')[0]
      window.sessionStorage.setItem('scroll_top', oapp.scrollTop)
      window.sessionStorage.setItem('paper_info', JSON.stringify(this.paperInfo))
      window.sessionStorage.setItem('paper_list', JSON.stringify(group_list))
      window.sessionStorage.setItem('image', JSON.stringify(this.images))
      this.$router.push('/dopaper_card?type=group')
    },
    async groupTest (form) {
      const { data } = await doPaper(form)
      this.publicQuestionsFunc(data)
    },
    setAnwer (id, answer) {
      var question = this.flatList[id]
      // 重复点击 取消选中
      if (question.answer.indexOf(answer) != -1) {
        question.answer.splice(question.answer.indexOf(answer), 1)
        return
      }
      question.topic = Number(question.topic)

      if (question.topic == 1 || question.topic == 9) {
        question.answer = [answer];
      } else if ([2, 3, 4].indexOf(question.topic) != -1) {
        //双选
        if (question.answer.length == 2 && question.topic == 4) {
          return
        }
        question.answer.push(answer)
      }

      this.paperList = this.getPaperList()
    },
    showSubmitDialog () {
      var group_list = [];
      var list = []
      Object.values(this.flatList).forEach(item => {
        list[item.answer_id] = item
      })

      Object.values(list).forEach(item => {
        if (Number(item.question_score)) {
          group_list.push(item)
        }
      })

      var num = 0;
      group_list.forEach(item => {
        var idx = this.$checkArr.findIndex(topic => topic == item.topic)
        if ((idx != -1 && item.answer.length == 0) || (idx == -1 && this.images.length == 0)) {
          num += 1;
        }
      })

      this.$refs.SubmitHintRef.num = num
      this.$refs.SubmitHintRef.dialogVisible = true
    },

    del (answer_id, index) {
      if (this.images.hasOwnProperty(answer_id)) { // 检查对象中是否存在指定的answer_id键
        let imageArray = this.images[answer_id]; // 获取指定answer_id键对应的数组
        if (index >= 0 && index < imageArray.length) { // 检查索引是否在合法范围内
          imageArray.splice(index, 1); // 使用splice函数从数组中删除指定索引的元素
        }
      } else {
        this.images.splice(index, 1)
      }
    },

    async uploadImage (answer_id) {
      if (this.uploadType == 'all') {
        if (this.images.length == 6) {
          this.$message.info("最多可添加6张图片")
          return
        }

        const { data } = await uploadImageAuto()
        this.images = Object.values(this.images);
        this.images.push(data.info.name)
      } else {
        if (this.images[answer_id] && this.images[answer_id].length == 3) {
          this.$message.info("每题最多可添加3张图片")
          return
        }

        const { data } = await uploadImageAuto()
        const newImage = {
          [answer_id]: [
            this.$addAllHeader(data.info.name)
          ]
        }
        if (this.images[answer_id]) {
          this.images[answer_id].push(this.$addAllHeader(data.info.name))
        }
        else {
          this.images = Object.assign({}, this.images, newImage);
        }
      }
      this.$message.success('上传成功')
    },
    getClass (item) {
      var str = ''
      // 单选题 topic
      if ((item.topic == 1 || item.topic == 9) && item.answer.length) {
        str = 'one_active'
      }
      // 纸上作答   当上传图片之后 高亮
      if (this.checkTopic.indexOf(item.topic) == -1 && this.images.length) {
        str = 'one_active'
      }
      if (item.answer.length) {
        str = 'one_active'
      }
      return str + ' card'
    },
    getAvlue (item, index) {
      if (item.topic == 1 || item.topic == 9 || item.topic == 2 || item.topic == 3 || item.topic == 4) {
        if (item.answer.length) {
          let arr = item.answer.slice().sort()
          return arr.join('')
        } else {
          return index
        }
      }
      return index
    },
    doPaper (index) {
      window.sessionStorage.setItem('topic_index', index)
      window.sessionStorage.setItem('image', JSON.stringify(this.images))
      this.$router.go(-1)
    },
    async submitPaper () {
      // let paper_info = JSON.parse(window.sessionStorage.getItem('paper_info'))
      let form = {
        user_paper_id: this.paperInfo.user_paper_id,
        chs_answer: {},
        other_answer: this.images
      }
      this.paperList.forEach(item => {
        // form.chs_answer[item.answer_id] = item.answer.join(',')
        if (item.answer && item.answer.length) {
          form.chs_answer[item.answer_id] = item.answer.join(',')
        }
      })
      form.other_answer = JSON.stringify(form.other_answer)
      await submitUserPaper(form)
      this.$message.success('提交成功!可前往我的试卷查看')
      this.isSubmit = false
      this.$router.go(-2)
      this.$removePaperInfo()
    },
    showDialog () {
      var num = 0
      this.paperList.forEach(item => {
        var idx = this.$checkArr.findIndex(topic => topic == item.topic)
        if ((idx != -1 && item.answer.length == 0) || (idx == -1 && this.images.length == 0)) {
          num += 1
        }
      })
      this.$refs.SubmitHintRef.num = num
      this.$refs.SubmitHintRef.dialogVisible = true
    },
    chooseUploadType(val) {
      this.uploadType = val
    }
  },
}
</script>

<style lang="scss" scoped>
.topic_warp {
  width: 100%;
  // min-height: 100vw;
  background: #f6f6f6;
  overflow-x: hidden;
  overflow-y: auto;
}
.paper_id {
  font-size: 20rem;
  padding: 10rem 40rem;
  font-weight: 700;
}

.wraper {
  height: calc(100vh - 200rem);
  overflow-y: auto;
}
.btn_warp {
  height: 120rem;
  .submit {
    width: calc(100vw - 200rem);
    margin-left: 40rem;
    margin-top: 50rem;
    margin-bottom: 50rem;
    font-size: 20rem;
    font-weight: bold;
    color: #ffffff;
    height: 60rem;
    background: #2196f3;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.image_wrap {
  background: #ffffff;
  width: calc(100% - 200rem);
  margin-left: 20rem;
  margin-top: 20rem;
  padding: 30rem;
  box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
  // height: 178rem;
  .title {
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
    span {
      color: #848484;
    }
  }
  .add_img {
    width: 120rem;
    height: 120rem;
    background: #f6f6f6;
    border-radius: 10rem;
    font-size: 20rem;
    font-weight: 500;
    color: #2196f3;
    text-align: center;
    cursor: pointer;
    img {
      width: 40rem;
      height: 34rem;
      margin-top: 26rem;
      margin-bottom: 6rem;
    }
  }
  .img_list {
    margin-top: 30rem;
    display: flex;

    .img_wrap {
      position: relative;
      margin-right: 24rem;
      display: flex;
      width: 120rem;
      height: 120rem;
      .del {
        position: absolute;
        right: -15rem;
        top: -15rem;
        width: 30rem;
        height: 30rem;
        cursor: pointer;
      }
      .answer {
        border-radius: 10rem;
        width: 120rem;
        height: 120rem;
      }
    }
  }
}


.dopaper_card {
  overflow: hidden;
  .top {
    width: 100vw;
    height: 100rem;
  }
  .title {
    text-indent: 40rem;
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
    span {
      color: #848484;
    }
  }
  .card_warp {
    // width: calc(100vw - 46rem);
    margin: 20rem 6rem 10rem 40rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    // max-height: 450rem;
    overflow-y: auto;
    .card {
      width: 120rem;
      height: 120rem;
      border-radius: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6f6f6;
      margin-right: 24rem;
      margin-bottom: 30rem;
      font-size: 16rem;
    }
  }
  .img_list {
    margin-top: 30rem;
    margin-left: 40rem;
    display: flex;
    flex-wrap: wrap;
    .img_card {
      display: flex;
      width: 610rem;
      margin-top: 10px;
    }
    .img_wrap {
      position: relative;
      // margin-right: 24rem;
      display: flex;
      // width: 120rem;
      height: 120rem;
      .del {
        position: relative;
        right: 15rem;
        top: -9rem;
        width: 30rem;
        height: 30rem;
        cursor: pointer;
      }
      .answer {
        border-radius: 10rem;
        width: 116rem;
        height: 120rem;
      }
    }
    .add_img {
      width: 120rem;
      height: 120rem;
      background: #f6f6f6;
      border-radius: 10rem;
      font-size: 20rem;
      font-weight: 500;
      color: #2196f3;
      text-align: center;
      cursor: pointer;
      img {
        width: 40rem;
        height: 34rem;
        margin-top: 26rem;
        margin-bottom: 6rem;
      }
    }
  }
  .btn_warp {
    // position: fixed;
    // bottom: 26rem;
    display: flex;
    align-items: flex-end;
    margin-bottom: 28rem;
    .fixed_bottom_btn {
      width: 300rem;
      margin-left: 180rem;
      font-size: 20rem;
      font-weight: bold;
      color: #ffffff;
      height: 60rem;
      background: #2196f3;
      border-radius: 10rem;
      justify-content: center;
      align-items: center;
    }
  }
}

.one_active {
  background: #ecf5fc !important;
  color: #2196f3 !important;
  font-weight: bold !important;
}
.image_wrap {
  // height: calc(100vh - 100rem);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>